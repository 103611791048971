/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import { restaurantPrefix } from '../../../__helpers/appRoutes';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export function DropdownMenu4() {
    return <>
        {/*begin::Navigation*/}
        <ul className="navi navi-hover py-5">
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <NavLink className="delivery-hour" to={restaurantPrefix() + "settings/delivery-hour"}>
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                        </span>
                        <span className="menu-text">Delivery Hours</span>
                    </NavLink>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <NavLink className="special-offer" to={restaurantPrefix() + "settings/special-offer"}>
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                        </span>
                        <span className="menu-text">Special Offers</span>
                    </NavLink>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <NavLink className="special-offer" to={restaurantPrefix() + "settings/reviews"}>
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                        </span>
                        <span className="menu-text">Reviews</span>
                    </NavLink>
                </a>
            </li>
            <li className="navi-separator my-3"></li>

            <li className="navi-item">
                <a href="#" className="navi-link">
                    <NavLink className="menu-link" to={restaurantPrefix() + "orders/manage-orders"}>
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                        </span>
                        <span className="menu-text">Manage Orders</span>
                    </NavLink>
                </a>
            </li>
            <li className="navi-separator my-3"></li>

            {/* <li className="navi-item">
                <a href="#" className="navi-link">
                    <NavLink className="menu-link" to={restaurantPrefix()+"tables/table-orders"}>
                        <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
                        </span>
                        <span className="menu-text">Table Orders</span>
                    </NavLink>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <NavLink className="menu-link" to={restaurantPrefix()+"tables/manage-tables"}>
                        <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
                        </span>
                        <span className="menu-text">Manage Tables</span>
                    </NavLink>
                </a>
            </li> */}
            {/* <li className="navi-separator my-3"></li> */}

            <li className="navi-item">
                <a href="#" className="navi-link">
                    <NavLink className="menu-link" to={restaurantPrefix() + "menu/manage-menu"}>
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                        </span>
                        <span className="menu-text">Manage Menu</span>
                    </NavLink>
                    {/* <span className="navi-link-badge">
                <span className="label label-light-primary label-inline font-weight-bold">new</span>
            </span> */}
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <NavLink className="menu-link" to={restaurantPrefix() + "menu/manage-categories"}>
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                        </span>
                        <span className="menu-text">Menu Categories</span>
                    </NavLink>
                </a>
            </li>
            <li className="navi-separator my-3"></li>


            <li className="navi-item">
                <a href="#" className="navi-link">
                    <NavLink className="menu-link" to={restaurantPrefix() + "customers"}>
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                        </span>
                        <span className="menu-text">Customers</span>
                    </NavLink>
                </a>
            </li>
            <li className="navi-separator my-3"></li>

            <li className="navi-item">
                <a href="#" className="navi-link">
                    <NavLink className="menu-link" to={restaurantPrefix() + "earnings"}>
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                        </span>
                        <span className="menu-text">Earnings</span>
                    </NavLink>
                </a>
            </li>

        </ul>
        {/*end::Navigation*/}

    </>
}
