/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import { adminPrefix } from '../../../__helpers/appRoutes';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export function DropdownMenu3() {
    return <>
        {/*begin::Naviigation*/}
        <ul className="navi">
            <li className="navi-item">
                <a href="#" className="navi-link">
                <NavLink className="contact" to={adminPrefix() + "settings/contact"}>
                <span className="svg-icon phone-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                </span>
                <span className="menu-text">Manage Contact</span>
                </NavLink>
                </a>
            </li>
            <li className="navi-separator my-3"></li>

            <li className="navi-item">
                <a href="#" className="navi-link">
                <NavLink className="menu-link" to={adminPrefix()+"users/manage-admins"}>
                    <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
                    </span>
                    <span className="menu-text">Manage Admins</span>
                </NavLink>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                <NavLink className="menu-link" to={adminPrefix()+"users/manage-restaurants"}>
                    <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
                    </span>
                    <span className="menu-text">Manage Restaurant</span>
                </NavLink>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                <NavLink className="menu-link" to={adminPrefix()+"users/manage-riders"}>
                    <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
                    </span>
                    <span className="menu-text">Manage Riders</span>
                </NavLink>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                <NavLink className="menu-link" to={adminPrefix()+"users/manage-users"}>
                    <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
                    </span>
                    <span className="menu-text">Manage Users</span>
                </NavLink>
                </a>
            </li>
            <li className="navi-separator my-3"></li>

            <li className="navi-item">
                <a href="#" className="navi-link">
                <NavLink className="menu-link" to={adminPrefix()+"orders/manage-orders"}>
                    <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
                    </span>
                    <span className="menu-text">Manage Order</span>
                </NavLink>
                </a>
            </li>
            <li className="navi-separator my-3"></li>

            <li className="navi-item">
                <a href="#" className="navi-link">
                <NavLink className="menu-link" to={adminPrefix()+"restaurants/requests"}>
                    <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
                    </span>
                    <span className="menu-text">Restaurant Requests</span>
                </NavLink>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                <NavLink className="menu-link" to={adminPrefix()+"restaurants/manage-category"}>
                    <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
                    </span>
                    <span className="menu-text">Manage Restro Category</span>
                </NavLink>
                </a>
            </li>

        </ul>
        {/*end::Naviigation*/}
    </>
}
