import axios from "axios";

export const FetchUserAction = (enqueueSnackbar) => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_ROUTES}/api/user`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    dispatch({
      type: "GET_USER",
      payload: {
        user: res.data.user?.userData,
        userDetail: res.data.user?.userDetail,
        favourite:res.data?.user?.favourites,
        type: res.data.type,
        token: res.data.token,
      },
    });
  } catch (e) {
    if(e.toString().includes("Network")){
      return enqueueSnackbar("Network error", {
        variant: "error",
        persist: true,
        anchorOrigin:{ horizontal: "center", vertical:"bottom" }
      });
    }
    console.log(e)
    
    return { error: e.response.data.message };
  }
};
