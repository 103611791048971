const initialState = {
  allOrders: [],
};

export const ManageOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ORDER":
      return {
        ...state,
        allOrders: action.payload.allOrders,
      };
    case "UPDATE_ORDER":
      const { orderStatus, orderId } = action.payload
      const parsedStatus = parseInt(orderStatus);
      let updatedOrders = [...state.allOrders];

      const orderIndex = updatedOrders.findIndex((order) => order.order_id === orderId);
      if (parsedStatus === 0) {
        const removedOrder = updatedOrders.filter(
          (e, idx) => idx !== orderIndex
        );
        return {
          ...state,
          allOrders: removedOrder,
        };
      } else {
        updatedOrders[orderIndex].status = parsedStatus;
        return {
          ...state,
          allOrders: updatedOrders,
        };
      }
    case 'ADD_ORDER':
      return {
        ...state,
        allOrders: [action.payload.order, ...state.allOrders],
      };
    default:
      return state;
  }
};
