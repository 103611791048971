import {combineReducers} from 'redux'
import {AuthReducer} from './AuthReducer'
import { TableOrderReducer } from './TableOrderReducer';
import { RiderAssignReducer} from './RiderAssignReducer';
import {CartReducer} from './CartReducer';
import {ModalReducer} from './ModalReducer';
import {RestaurantDetailReducer} from './RestaurantDetailReducer';
import {RestaurantListingReducers} from './RestaurantListingReducers';
import {HomePageReducer} from './HomePageReducer';
import {ManageOrderReducer} from './ManageOrderReducer';
import {ReservationReducer} from './ReservationReducer';
import {NotificationReducer} from './NotificationReducer';

const rootReducer = combineReducers({
    user:AuthReducer,
    order:TableOrderReducer,
    rider:RiderAssignReducer,
    cart:CartReducer,
    modal:ModalReducer,
    restaurantDetail:RestaurantDetailReducer,
    restaurantLists:RestaurantListingReducers,
    homepage:HomePageReducer,
    allOrders:ManageOrderReducer,
    reservations:ReservationReducer,
    notifications:NotificationReducer,
});

export default rootReducer;
