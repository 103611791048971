const initialState = {
    reservations: [],
};

export const ReservationReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_RESERVATION":
            return {
                ...state,
                reservations: action.payload.reservations,
            };
        case "UPDATE_RESERVATION":
            const { reservationStatus, reservationId } = action.payload
            const parsedStatus = parseInt(reservationStatus);
            let updatedReservations = [...state.reservations];
            const ReservationIndex = updatedReservations.findIndex(
                (Reservation) => Reservation.id === reservationId
            );
            console.log(ReservationIndex,reservationId,reservationStatus,updatedReservations)

            // const updatedRestro = updatedReservations[ReservationIndex].restro_detail.restro_name;
            updatedReservations[ReservationIndex].status = parsedStatus;

            return {
                ...state,
                reservations: updatedReservations,
            };
        case 'ADD_RESERVATION':
            return {
                ...state,
                reservations: [action.payload.reservation, ...state.reservations],
            };
        default:
            return state;
    }
};
