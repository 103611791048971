/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import SVG from "react-inlinesvg";
import { Redirect, useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  adminPrefix,
  restaurantPrefix,
  riderPrefix,
} from "../../../../../__helpers/appRoutes";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useSelector } from "react-redux";
import Avatar from "boring-avatars";
import { postData } from "../../../../../__helpers/apiCalls";
import { LogoutRoute } from "../../../../../__helpers/ApiRoutes";

export function QuickUser() {
  const history = useHistory();
  const { userData, type } = useSelector((state) => state.user);

  const logoutClick = async () => {
    await postData(LogoutRoute());
    window.location.reload();
  };

  const checkUser = (param) => {
    switch (type) {
      case 4:
        return riderPrefix();
      case 3:
        return <Redirect to="/404" />;
      case 2:
        return restaurantPrefix();
      case 1:
        return adminPrefix();
      default:
        break;
    }
  };

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">User Profile</h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5">
            <div className="symbol-label" style={{ backgroundColor: "white" }}>
              <Avatar
                size={80}
                name={userData.name}
                variant="beam"
                colors={["#65F657", "#3A3C3B", "#BAAB6A", "#FBEA80", "#FDFAC7"]}
              />
            </div>
            <i className="symbol-badge bg-success" />
          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {userData.name}
            </a>
            <div className="text-muted mt-1">Application Developer</div>
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {userData.email}
                  </span>
                </span>
              </a>
            </div>
            {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
            <button
              className="btn btn-light-primary btn-bold"
              onClick={logoutClick}
            >
              Sign out
            </button>
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />

        <div className="navi navi-spacer-x-0 p-0">
          <a className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Notification2.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <NavLink
                  className="user-profile"
                  to={checkUser() + "settings/profile"}
                >
                  <div className="font-weight-bold">My Profile</div>
                  <div className="text-muted">
                    Account settings and more{" "}
                    <span className="label label-light-danger label-inline font-weight-bold">
                      edit
                    </span>
                  </div>
                </NavLink>
              </div>
            </div>
          </a>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />

        <div className="navi navi-spacer-x-0 p-0">
          <a className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Notification2.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <NavLink
                  className="security"
                  to={checkUser() + "settings/security"}
                >
                  <div className="font-weight-bold">Security</div>
                  <div className="text-muted">
                    Password changes and more{" "}
                    <span className="label label-light-danger label-inline font-weight-bold">
                      update
                    </span>
                  </div>
                </NavLink>
              </div>
            </div>
          </a>
        </div>

        <div className="separator separator-dashed my-7"></div>
      </div>
    </div>
  );
}
