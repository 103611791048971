/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_metronic/_helpers";
import {riderPrefix} from '../../../../__helpers/appRoutes';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

           {/* Applications */}
          {/* begin::section */}
          <li className="menu-section ">
            <h4 className="menu-text">Orders</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end:: section */}

          {/*begin::1 Manage Order*/}
          <li
              className={`menu-item ${getMenuItemActive("/manage-orders", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to={riderPrefix()+"orders/manage-orders"}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
            </span>
              <span className="menu-text">Manage Orders</span>
            </NavLink>
          </li>
          {/*end::1 Manage Order*/}
            {/* begin::section */}
            <li className="menu-section ">
            <h4 className="menu-text">My History</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end:: section */}
          {/*begin::1 My History*/}
          <li
              className={`menu-item ${getMenuItemActive("/my-history", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to={riderPrefix()+"orders/my-history"}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
            </span>
              <span className="menu-text">My History</span>
            </NavLink>
          </li>
          {/*end::1 My History*/}
         
        
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
