
const initialState = {
  restaurants: [],
  offers: [],
};

export const HomePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_HOMEPAGE":
      return {
        ...state,
        restaurants: action.payload.restaurants,
        offers: action.payload.offers,
      };
    default:
      return state;
  }
};
