import axios from "axios";

export const postData = async (route, body, method = "POST") => {
  try {
    const res =
      method === ("POST" || "post")
        ? await axios.post(
            `${process.env.REACT_APP_API_ROUTES}/${route}`,
            JSON.stringify(body),
            {
              withCredentials: true,
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )
        : await axios.put(
            `${process.env.REACT_APP_API_ROUTES}/${route}`,
            JSON.stringify(body),
            {
              withCredentials: true,
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          );
    return { data: res.data };
  } catch (e) {
    throw e.response.data;
  }
};
export const fetchData = async (route) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_ROUTES}/${route}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return { data: res.data };
  } catch (e) {
    if (e.response) {
      //got a response from a server 
      if (e.response.status === 401) {
        return (window.location.href = "/");
      } else if (e.response.status === 404) {
        return window.location.href = "/404";
      }
    } else if (e.request) {
      //if request was made but no response received
      if (String(e).includes("Network Error")) {
        throw { error: "Network Error", status: 500 };
      }
    }
    return { error: e, status: e.response.status };
  }
};

export const deleteData = async (route) => {
  try {
    const res = await axios.delete(`${process.env.REACT_APP_API_ROUTES}/${route}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return { data: res.data };
  } catch (e) {
    return { error: e };
  }
};

export const LoginUser = async (body) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_ROUTES}/api/login`, JSON.stringify(body), {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  } catch (e) {
    // setError(e.message);
    console.log(e, "login error");
  }
};
export const LoginAuthority = async (body) => {
  try {
    const res =await axios.post(
      `${process.env.REACT_APP_API_ROUTES}/api/login-authority`,
      JSON.stringify(body),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  } catch (e) {
    // setError(e.message);
    console.log(e, "login error");
  }
};

export const postWithImage = async (route, body) => {

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_ROUTES}/${route}`, body, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return { data: res.data };
  } catch (e) {
    throw e.response.data;
  }
};
