/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { restaurantPrefix } from '../../../__helpers/appRoutes';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
  DropdownItemToggler,
  DropdownMenu4,
} from "../../../_metronic/_partials/dropdowns";
// import 

export function AsideSearch({ isActive }) {
  return (
    <div
      className={`tab-pane p-3 px-lg-7 py-lg-5 fade ${isActive &&
        "show active"}`}
    >
      {/* begin::Form */}
      <form className="p-2 p-lg-3">
        <div className="d-flex" style={{ justifyContent: 'flex-end' }}>
          {/* <div className="input-icon h-40px">
            <input
              type="text"
              className="form-control form-control-lg form-control-solid h-40px"
              placeholder="Search..."
              id="generalSearch"
              autoComplete="false"
            />
            <span>
              <span className="svg-icon svg-icon-lg">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                />
              </span>
            </span>
          </div> */}

          <Dropdown drop="down" alignRight>
            <Dropdown.Toggle
              as={DropdownItemToggler}
              id="kt_quick_actions_search_toggle"
            >
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id="quick-search-tooltip">Quick search</Tooltip>
                }
              >
                <a
                  href="#"
                  className="btn btn-icon btn-default btn-hover-primary ml-2 h-40px w-40px flex-shrink-0"
                >
                  <span className="svg-icon svg-icon-xl">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
              <DropdownMenu4 />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </form>
      {/* end::Form */}

      <p className="p-2 p-lg-3 my-1 my-lg-3" style={{ color: "grey" }}>Time</p>

      {/* begin::List */}

      {/* begin::Item */}
      <div className="list-item hoverable p-2 p-lg-3 mb-2">
        <div className="d-flex align-items-center">
          {/* begin::Symbol */}

          {/* end::Symbol */}
          {/* begin::Text */}
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <NavLink className="delivery-hour" to={restaurantPrefix() + "settings/delivery-hour"}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")} />
              </span>
              <span className="menu-text">Opening Hours</span>
            </NavLink>
          </div>
          {/* begin::End */}
        </div>
      </div>
      {/* end::Item */}


      <p className="p-2 p-lg-3 my-1 my-lg-3" style={{ color: "grey" }}>Offer</p>

      {/* begin::List */}

      {/* begin::Item */}
      <div className="list-item hoverable p-2 p-lg-3 mb-2">
        <div className="d-flex align-items-center">
          {/* begin::Symbol */}

          {/* end::Symbol */}
          {/* begin::Text */}
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <NavLink className="special-offer" to={restaurantPrefix() + "settings/special-offer"}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")} />
              </span>
              <span className="menu-text">Special Offers</span>
            </NavLink>
          </div>
          {/* begin::End */}
        </div>
      </div>
      {/* end::Item */}

      <p className="p-2 p-lg-3 my-1 my-lg-3" style={{ color: "grey" }}>Reviews</p>

      {/* begin::List */}

      {/* begin::Item */}
      <div className="list-item hoverable p-2 p-lg-3 mb-2">
        <div className="d-flex align-items-center">
          {/* begin::Symbol */}

          {/* end::Symbol */}
          {/* begin::Text */}
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <NavLink className="special-offer" to={restaurantPrefix() + "settings/reviews"}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")} />
              </span>
              <span className="menu-text">Reviews</span>
            </NavLink>
          </div>
          {/* begin::End */}
        </div>
      </div>
      {/* end::Item */}
      <p className="p-2 p-lg-3 my-1 my-lg-3" style={{ color: "grey" }}>Coupon</p>

      {/* begin::List */}

      {/* begin::Item */}
      <div className="list-item hoverable p-2 p-lg-3 mb-2">
        <div className="d-flex align-items-center">
          {/* begin::Symbol */}

          {/* end::Symbol */}
          {/* begin::Text */}
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <NavLink className="special-offer" to={restaurantPrefix() + "settings/coupon"}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")} />
              </span>
              <span className="menu-text">Coupons</span>
            </NavLink>
          </div>
          {/* begin::End */}
        </div>
      </div>
      {/* end::Item */}

    </div>
  );
}
