const initialState = {
    order:{},
    loading:false,
}

export const TableOrderReducer =(state=initialState,action)=>{
    switch(action.type){
        case "Get_Table_Order":
            return{
                ...state,
                order:action.payload.order,
                loading:false
            }
        case "Getting_Order":
            return{
                ...state,
                loading:true
            }
        default:
            return state;
    }
}
