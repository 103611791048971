const initialState = {
    userData:{},
    userDetail:{},
    type:0,
    isAuthenticated:false,
    token:"",
    favourites:[]
}

export const AuthReducer =(state=initialState,action)=>{
    switch(action.type){
        case "GET_USER":
            return{
                ...state,
                userData:action.payload.user,
                userDetail:action.payload.userDetail,
                type:action.payload.type,
                isAuthenticated:true,
                token:action.payload.token,
                favourites:action.payload.favourite
            }
        default:
            return state;
    }
}
