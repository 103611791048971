/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { postData } from "../../../../../__helpers/apiCalls";
import { RestroTakingOrdersRoute, RestroDeliveryRoute } from "../../../../../__helpers/ApiRoutes";
import { toAbsoluteUrl } from "../../../../_helpers";
import { CircularProgress, Switch } from "@material-ui/core";
import { useSelector } from "react-redux";

export function QuickActions() {
  const [loadingTakingOrder, setLoadingTakingOrder] = useState(false);
  const [loadingDelivery, setLoadingDelivery] = useState(false);
  const [takingOrders, setTakingOrders] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const { userDetail } = useSelector(state => state.user)

  useEffect(() => {
    setTakingOrders(userDetail.takingorders);
    setDelivery(userDetail.delivery);
  }, [userDetail.delivery, userDetail.takingorders])

  const toggleTakingOrder = async () => {
    setLoadingTakingOrder(true);
    const data = await postData(RestroTakingOrdersRoute());
    setTakingOrders(data.data.takingOrders);
    setLoadingTakingOrder(false);
  };

  const toggleDelivery = async () => {
    setLoadingDelivery(true);
    const data = await postData(RestroDeliveryRoute());
    setDelivery(data.data.delivery);
    setLoadingDelivery(false);
  };

  return (
    <>
      {/* begin::Quick Actions Panel */}
      <div id="kt_quick_actions" className="offcanvas offcanvas-left p-10">
        {/* begin::Header */}
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-10">
          <h3 className="font-weight-bold m-0">Quick Actions</h3>
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_actions_close"
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </a>
        </div>
        {/* end::Header */}

        {/* begin::Content */}
        <div className="offcanvas-content pr-5 mr-n5">
          <div className="row gutter-b">
            {/* begin::Item */}
            <div className="col-6">
              <a
                onClick={toggleTakingOrder}
                href="#"
                className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
              >
                {!loadingTakingOrder ? (
                  <>
                    <span className="svg-icon svg-icon-3x svg-icon-primary m-0">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Shopping/Box2.svg"
                        )}
                      />
                    </span>
                    <span className="d-block font-weight-bold font-size-h6 mt-2">
                      Taking Orders
                    </span>
                    <Switch
                      checked={takingOrders}
                      name="checkedA"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </>
                ) : (
                  <CircularProgress style={{ color: "tomato" }} />
                )}
              </a>
            </div>
            {/* end::Item */}
            {/* begin::Item */}
            <div className="col-6">
              <a
                onClick={toggleDelivery}
                href="#"
                className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
              >
                {!loadingDelivery ? (
                  <>
                    <span className="svg-icon svg-icon-3x svg-icon-primary m-0">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Shopping/Box2.svg"
                        )}
                      />
                    </span>
                    <span className="d-block font-weight-bold font-size-h6 mt-2">
                      Delivery
                    </span>
                    <Switch
                      checked={delivery}
                      name="checkedA"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </>
                ) : (
                  <CircularProgress style={{ color: "tomato" }} />
                )}
              </a>
            </div>
            {/* end::Item */}
          </div>
        </div>
      </div>
      {/* end::Content */}
      {/* end::Quick Actions Panel */}
    </>
  );
}
