import _ from "lodash";

const initialState = {
  restaurants: [],
  categories: [],
  filteredRestaurants: [],
  filterLists: [],
  sortBy: "rated"
};

export const RestaurantListingReducers = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LISTING":
      return {
        ...state,
        restaurants: action.payload.lists,
        categories: action.payload.categories,
        filteredRestaurants: action.payload.lists,
      };
    case "ADD_OR_REMOVE_FILTER":
      let newFilter = state.filterLists;
      let newCategory = state.restaurants;

      newFilter.indexOf(action.payload.filter) === -1
        ? newFilter.push(action.payload.filter)
        : newFilter.splice(newFilter.indexOf(action.payload.filter), 1);

        if(newFilter.length!==0){
          newCategory = newCategory.filter(
            (cat) => _.intersection(cat.tags, newFilter).length > 0
          );
        }

      return {
        ...state,
        filterLists: newFilter,
        filteredRestaurants: newCategory,
      };
    default:
      return state;
  }
};
