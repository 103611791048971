const initialState = {
  menuItems: [],
  menuItemsWithCategory: [],
  restro: {},
  table: {},
  deliveryhour: [],
  review: [],
};

export const RestaurantDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MENU":
      const menuItemsArray = [];
      action.payload.menuItems.map(({ menu_item }) => {
        menu_item.map((e) => {
          menuItemsArray.push(e);
        });
      });
      return {
        ...state,
        menuItems: menuItemsArray,
        menuItemsWithCategory: action.payload.menuItems,
        restro: action.payload.restro,
        deliveryhour: action.payload.deliveryhour,
        review: action.payload.review,
      };
    default:
      return state;
  }
};
