const initialState = {
  menuItems: [],
  menuItemsWithCategory: [],
  addedItems: [],
  total: 0,
  delivery_type: "Delivery",
  payment_type: "Cash",
  suggestion: "No suggestion",
  delivery_details: "",
  delivery_time: "",
  restro: {},
  orderDetails:{}
};

export const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_MENU_ITEMS":
      const menuItemsArray = [];
      action.payload.menuItems.map(({ menu_item_in_stock }) => {
        return menu_item_in_stock.map((e) =>{
          e.menu_items_id = e.id;
          return menuItemsArray.push(e);
        });
      });

      return {
        ...state,
        menuItems: menuItemsArray,
        menuItemsWithCategory: action.payload.menuItems,
        restro: action.payload.restro,
      };
    case "ADD_TO_CART":
      let addedItem = state.menuItems.find(
        (item) => item.id === action.payload.id
      );
      let existedItem = state.addedItems.find(
        (item) => action.payload.id === item.id
      );
      if (existedItem) {
        addedItem.quantity += 1;
        addedItem.totalPrice += addedItem.discount;
        return { ...state, total: state.total + addedItem.discount };
      } else {
        addedItem.quantity = 1;
        addedItem.totalPrice = addedItem.discount;

        //calculating the total
        let newTotal = state.total + addedItem.discount;

        return {
          ...state,
          addedItems: [...state.addedItems, addedItem],
          total: newTotal,
        };
      }
    case "ADD_TO_CART_ADMIN":
      let adminAddedItem = state.menuItems.find(
        (item) => item.id === action.payload.id
      );
      adminAddedItem.quantity = action.payload.qty;

      //calculating the total

      return {
        ...state,
        addedItems: [...state.addedItems, adminAddedItem],
      };
    case "REMOVE_ITEM":
      let itemToRemove = state.addedItems.find(
        (item) => action.payload.id === item.id
      );
      let new_items = state.addedItems.filter(
        (item) => action.payload.id !== item.id
      );

      //calculating the total
      let newTotal = state.total - itemToRemove.price * itemToRemove.quantity;
      console.log(itemToRemove);
      return {
        ...state,
        addedItems: new_items,
        total: newTotal,
      };
    case "CLEAR_ADDED_ITEMS":
      return {
        ...state,
        addedItems: [],
        total: 0,
      };

    case "CHANGE_ORDER_TYPE":
      return {
        ...state,
        delivery_type: action.payload.orderType,
      };
    case "CHANGE_PAYMENT_TYPE":
      return {
        ...state,
        payment_type: action.payload.paymentType,
      };
    case "SET_DELIVERY_DETAIL":
      return {
        ...state,
        delivery_details: action.payload.delivery_detail,
      };
    case "SET_SUGGESTION":
      return {
        ...state,
        suggestion: action.payload.suggestion,
      };
    case "SET_TIME":
      // const deliveryTime = action.payload.delivery_time
      //   .toString()
      //   .split(" ")[4];
      console.log(action.payload.delivery_time)
      return {
        ...state,
        delivery_time: action.payload.delivery_time,
      };
      
    case "ADMIN_ORDER_DETAIL":
      return {
        ...state,
        orderDetail: action.payload.orderDetail,
      };

    default:
      return state;
  }
};
