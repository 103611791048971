import React, { useEffect, Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FetchUserAction } from "./actions/LoginAction";
import { useSnackbar } from 'notistack';

const Routes = lazy(() => import("./app/Routes"));
const AppRoute = lazy(() => import("./FrontEnd_App/AppRoute"));
const MainRoutes = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const userType = useSelector((state) => state.user.type);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const loginUser = () => {
      dispatch(FetchUserAction(enqueueSnackbar));
    };
    loginUser();
  }, [dispatch, isAuthenticated]);

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path="/dashboard">
          <Routes />
        </Route>
        {/* <Route path="/">
          <AppRoute />
        </Route> */}
      </Switch>
    </Suspense>
  );
};

export default MainRoutes;
