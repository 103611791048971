const initialState = {
    notifications:[],
}

export const NotificationReducer=(state=initialState,action)=>{
    switch(action.type){
        case "NEW_NOTIFICATION":
            const newOrder = [action.payload,...state.notifications];
            return{
                ...state,
                notifications:newOrder
            }
        default:
            return state;
    }
}
