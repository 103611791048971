const initialState = {
    riders:{},
    orderId:"",
    loading:false,
}

export const RiderAssignReducer =(state=initialState,action)=>{
    switch(action.type){
        case "Get_Riders":
            return{
                ...state,
                riders:action.payload.riders,
                orderId:action.payload.orderId,
                loading:false
            }
        default:
            return state;
    }
}
