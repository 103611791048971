/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n";
import { MaterialThemeProvider } from "../_metronic/layout";
import { SnackbarProvider } from "notistack";
import Grow from '@material-ui/core/Grow';
import { Button } from "@material-ui/core";
import MainRoutes from "../MainRoutes";
import {ErrorBoundary} from 'react-error-boundary'

export default function App({ basename }) {
  const notistackRef = React.createRef();
const onClickDismiss = key => () => { 
    notistackRef.current.closeSnackbar(key);
}
function ErrorFallback({error, resetErrorBoundary}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}
const myErrorHandler = (error, info) => {
  // Do something with the error
  // E.g. log to an error logging client here
  console.log(error,info);
}

  return (
    <BrowserRouter basename={basename}>
      {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
      <MaterialThemeProvider>
        {/* Provide `react-intl` context synchronized with Redux state.  */}
        <I18nProvider>
          {/* Render routes with provided `Layout`. */}
          <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
          <SnackbarProvider
            maxSnack={4}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            TransitionComponent={Grow}
            ref={notistackRef}
            action={(key) => (
                <Button onClick={onClickDismiss(key)} style={{color:'white'}}>
                    Dismiss
                </Button>
            )}
          >
            <MainRoutes />
          </SnackbarProvider>
          </ErrorBoundary>
        </I18nProvider>
      </MaterialThemeProvider>
    </BrowserRouter>
  );
}
