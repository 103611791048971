//**Restaurant Routes*/
//?Menu Item and Category
exports.MenuCategoryRoute = () =>
  "api/customeradmin/dashboard/restaurant/menu/category";
exports.MenuItemRoute = () =>
  "api/customeradmin/dashboard/restaurant/menu/item";
exports.MenuItemToggleRoute = (itemId) =>
  `api/customeradmin/dashboard/restaurant/menu/item/${itemId}/stock`;
exports.MenuItemRemoveImageRoute = (itemId) =>
  `api/customeradmin/dashboard/restaurant/menu/item/${itemId}/removeImage`;

exports.RestaurantTableRoute = () =>
  "api/customeradmin/dashboard/restaurant/table";

//?Order
exports.RestaurantTableOrderRoute = () =>
  "api/customeradmin/dashboard/restaurant/orders";

exports.RestaurantTableOrderCompleteRoute = () =>
  "api/customeradmin/dashboard/restaurant/order/complete";

exports.RestaurantOnlineOrders = () =>
  "api/customeradmin/dashboard/restaurant/online-orders";

exports.RestaurantOnlineOrderHistory = () =>
  "api/customeradmin/dashboard/restaurant/orders/history";

exports.RestaurantOrderNextStep = () =>
  "api/customeradmin/dashboard/restaurant/online-orders/next-step";

exports.RestaurantOrderPrevStep = () =>
  "api/customeradmin/dashboard/restaurant/online-orders/prev-step";

exports.RestaurantTableOrderRoute = () =>
  "api/customeradmin/dashboard/restaurant/orders";

exports.RestaurantTableOrderCompleteRoute = () =>
  "api/customeradmin/dashboard/restaurant/order/complete";

exports.RestaurantOnlineOrders = () =>
  "api/customeradmin/dashboard/restaurant/online-orders";

exports.RestaurantCustomOrders = () =>
  "api/customeradmin/dashboard/restaurant/order/create";

exports.RestaurantOnlineOrderHistory = () =>
  "api/customeradmin/dashboard/restaurant/orders/history";

exports.RestaurantOrderNextStep = () =>
  "api/customeradmin/dashboard/restaurant/online-orders/next-step";

exports.RestaurantOrderPrevStep = () =>
  "api/customeradmin/dashboard/restaurant/online-orders/prev-step";

exports.RestaurantOrderCancel = (id) =>
  `api/customeradmin/dashboard/restaurant/orders/cancel/${id}`;
exports.RestaurantOrderAccept = (id) =>
  `api/customeradmin/dashboard/restaurant/orders/accept/${id}`;

exports.RestaurantOrderAdminMessage = (id) =>
  `api/customeradmin/dashboard/restaurant/orders/message/${id}`;

exports.RestaurantAdminNoticeCreate = () =>
  `api/customeradmin/dashboard/restaurant/notice/create`;

exports.RestaurantAdminNoticeDelete = () =>
  `api/customeradmin/dashboard/restaurant/notice/delete`;

exports.RestaurantAdminNotice = () =>
  `api/customeradmin/dashboard/restaurant/notice`;

exports.RestaurantPrinterSettings = () =>
  `api/customeradmin/dashboard/restaurant/printer`;


//?delivery Hours
exports.RestroDeliveryHours = () =>
  "api/customeradmin/dashboard/restaurant/deliveryhours";
exports.ToggleRestroDeliveryHours = (id) =>
  `api/customeradmin/dashboard/restaurant/deliveryhours/${id}/toggle`;

//?special Offers
exports.RestroOffers = () => "api/customeradmin/dashboard/restaurant/offers";

//?coupon code
exports.RestroCoupons = () => "api/customeradmin/dashboard/restaurant/coupon";
exports.RestroLoyalCoupons = (id) => `api/customeradmin/dashboard/restaurant/coupon/create/${id}`;

//?Reviews
exports.RestroReviewsRoute = () => "api/customeradmin/dashboard/restaurant/reviews";
//?Dashboard
exports.RestroDashboardRoute = () => "api/customeradmin/dashboard";

//?Taking Orders
exports.RestroTakingOrdersRoute = () => "api/customeradmin/taking_orders";

//?Takeaway
exports.RestroDeliveryRoute = () => "api/customeradmin/delivery";

//?Reservations

exports.RestaurantReservationRoute = () =>
  "api/customeradmin/dashboard/restaurant/reservation";

exports.RestaurantReservationCancel = (id) =>
  `api/customeradmin/dashboard/restaurant/reservation/cancel/${id}`;

exports.RestaurantReservationAccept = (id) =>
  `api/customeradmin/dashboard/restaurant/reservation/accept/${id}`;

exports.RestaurantCustomReservation = () =>
  "api/customeradmin/dashboard/restaurant/reservation/create";


//**Restaurant Routes End*/

//*Public Routes*/
//user
exports.CreateTableOrderRoute = () => "api/table-order/";
exports.HomePageRoute = () => "api/homepage/";
exports.RestaurantListsRoute = () => "api/restaurants/";
exports.LogoutRoute = () => "api/logout/";
exports.RestaurantCategoriesRoute = () => "api/restaurant/category";
//*Public Routes End*/

//Restaurant Request/Register

exports.RegisterRestaurant = () => "api/registerRestaurant";
exports.ContactUsRoute = () => "api/contact";
exports.AdminContactUsRoute = () => "api/admin/contact";

//**Admin Routes */
//?Orders

exports.AdminOnlineOrders = () => "api/admin/dashboard/orders";
exports.AssignOrder = () => "api/admin/dashboard/riders/assign-order";
exports.AdminOrderNextStep = () => "api/admin/dashboard/orders/next-step";
exports.AdminOrderPrevStep = () => "api/admin/dashboard/orders/prev-step";
exports.InactiveUserRiderRoute = () => "api/admin/dashboard/inactive-rider";

//?Manage Users
exports.UserAdminRoute = () => "api/admin/dashboard/admin-users";
exports.UserRestaurantRoute = () => "api/admin/dashboard/restaurants";
exports.UserRoute = () => "api/admin/dashboard/users";
exports.UserRiderRoute = () => "api/admin/dashboard/rider";

//?Restaurant Requests and Approve
exports.Approve = () => "api/admin/dashboard/restaurant/approve";
exports.NewRequests = () => "api/admin/dashboard/restaurant/requests";

//?Restaurant Categories
exports.RestaurantCategory = () => "api/admin/dashboard/restaurant/category";

//**Admin Routes End*/

//**Authenticated Users Route */
exports.RestroReviewRoute = () => "api/user/review";
exports.DeliveryDetailsRoute = () => "api/user/delivery-details";
exports.CreateOnlineOrderRoute = () => "api/user/order";
exports.FavouriteRoute = () => "api/user/favorite";
//**Authenticated Users Route End*/

//**Riders Route */
//?Orders
exports.RiderOnlineOrders = () => "api/rider/dashboard/orders";
exports.RiderOrderHistory = () => "api/rider/dashboard/order-history";
exports.RiderOrderNextStep = () => "api/rider/dashboard/orders/next-step";
exports.RiderOrderPrevStep = () => "api/rider/dashboard/orders/prev-step";
//**Riders Route End*/

//updating profile

exports.UpdateUserProfile = () => "api/update_profile";

//updating password/ security

exports.ManageSecurity = () => "api/change_password";


//update restro logo/ restro profile

exports.UpdateRestroLogo = () => "api/customeradmin/update_logo";

//update restro detail/ restro profile

exports.UpdateRestroDetailProfile = () => "api/customeradmin/update_detail";

//update rider detail/ rider profile

exports.UpdateRiderDetailProfile = () => "api/rider/update_rider_detail";

//restro photos upload

exports.UploadRestroPhotos = () => "api/customeradmin/upload_images";

// restro cover photo upload

exports.UploadRestroCoverPhoto = () => "api/customeradmin/upload_cover_image";

//restro delete showcase image

exports.DeleteShowcaseImage = () => "api/customeradmin/delete_showcase_image";

//customer
exports.GetCustomerDetails = () => "api/customeradmin/dashboard/restaurant/customers";